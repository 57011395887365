import { FC, useState } from "react";
import { useCompatibilityMatrix } from "../../modules/deduction/deduction.fn";
import { LoginHeader } from "../../modules/landing/navigation/login-header";
import { useUsers } from "../../modules/user/user.query";
import {
  getByCoupon,
  getUserIdsByCoupon,
} from "../../modules/coupon/coupon.fn";
import { useOrders } from "../../modules/order/order.fn";
import { useNarrative } from "../../modules/narrative/narrative.fn";
import { useCoupons } from "../../modules/coupon/coupon.query";
import "./CompatibilityMatrix.scss";
import { Button } from "react-bootstrap";
import {
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

function getBackground(c: number) {
  if (c >= 76) {
    return "#82aaf9";
  }

  if (c >= 51) {
    return "#33337f";
  }

  if (c >= 26) {
    return "#ffe200";
  }

  if (c === -1) {
    return "#b6b8b9";
  }

  return "#ff8c42";
}

export const CompatibilityMatrix: FC = () => {
  const [selectedCoupons, setSelectedCoupons] = useState<
    { id: number; code: string }[]
  >([]);

  const [selectedUsers, setSelectedUsers] = useState<
    { id: number; name: string }[]
  >([]);

  const [selectedCompatibility, setSelectedCompatibility] = useState(
    [] as any[]
  );

  const { orders } = useOrders();

  const { data: users } = useUsers();

  const { data: coupons, isLoading: isLoadingCoupons } = useCoupons();

  const { data: narrativeData, isLoading: isLoadingNarrative } = useNarrative();

  const ids = getUserIdsByCoupon(
    orders,
    selectedCoupons.map((c) => c.id)
  );

  const usersByCoupon =
    selectedCoupons.length === 0
      ? users
      : users?.filter((u: any) => ids.includes(u.id));

  const narrativeDataByCoupon = getByCoupon(narrativeData, ids);

  const workGroupData = narrativeDataByCoupon
    ?.filter((d: any) => {
      return (
        d.userId &&
        d.rankedValues &&
        d.valuesPhilosophy &&
        d.archetype &&
        d.archetypeExplanation &&
        d.triggers &&
        d.triggersExplanation
      );
    })
    .map((d: any) => {
      return {
        id: d.id,
        userId: d.userId,
        rankedValues: d.rankedValues,
        valuesPhilosophy: d.valuesPhilosophy,
        archetype: d.archetype,
        archetypeExplanation: d.archetypeExplanation,
        triggers: d.triggers,
        triggersExplanation: d.triggersExplanation,
      };
    });

  const workGroupDataUnique = workGroupData?.reduce((acc, cur) => {
    const hasThisUserAlready = !!acc.find((el) => el.userId === cur.userId);

    if (!hasThisUserAlready) {
      acc.push(cur);
    }

    return acc;
  }, [] as any[]);

  const usersByCouponFiltered = usersByCoupon?.filter((u: any) =>
    workGroupDataUnique?.map((w) => w.userId).includes(u.id)
  );

  //   const workGroupDataUniqueSliced = workGroupDataUnique?.slice(0, 10);

  const workGroupDataUniqueSliced = workGroupDataUnique?.filter((d) => {
    const ids = selectedUsers.map((u) => u.id);

    return ids.includes(d.userId);
  });

  const {
    data: compatibilityData,
    isLoading: isLoadingCompatibilityMatrix,
    refetch: fetchCompatibilityMatrix,
  } = useCompatibilityMatrix({
    workGroupData: workGroupDataUniqueSliced,
    users: workGroupData?.length || 0,
  });

  const m = compatibilityData?.matrix;

  const args = {
    activeShape: {
      fill: "red",
    },
    activeIndex: undefined,
  };

  const individualReportData = selectedCompatibility
    .filter((d: any) => d.compatibility >= 0)
    .map((d) => {
      return {
        ...d,
        color: getBackground(d.compatibility),
        user: users.find((u: any) => u.id === d.id)?.fullName,
      };
    });

  const selectedUserForIndividualReport = users?.find(
    (u: any) =>
      u.id === selectedCompatibility?.find((d) => d.compatibility === -1)?.id
  )?.fullName;

  return (
    <div className="dashboard-wrapper">
      <LoginHeader />

      <div>
        <div className="dashboard-panel">
          <label>
            <span className="filter-label">Filter by coupon:</span>

            <select
              onChange={(e) => {
                const code = e.target.value;

                const coupon = coupons?.find((c) => c.code === code);

                // Clear filter when selecting "All" groups
                if (code === "All") {
                  setSelectedCoupons([]);

                  return;
                }

                // Early return undefined
                if (!coupon) {
                  return;
                }

                // Coupon already in filters
                if (selectedCoupons.map((c) => c.code).includes(code)) {
                  return;
                }

                setSelectedCoupons([...selectedCoupons, coupon]);
              }}
            >
              <option key={0} value="All">
                {isLoadingCoupons ? "Loading..." : "All"}
              </option>

              {coupons?.map((c) => (
                <option key={c.id} value={c.code}>
                  {c.code}
                </option>
              ))}
            </select>
          </label>

          {!!selectedCoupons.length && (
            <div className="dashboard-coupons">
              <span className="filter-label">Selected groups:</span>

              {selectedCoupons.map((coupon) => (
                <div
                  key={coupon.id}
                  className="dashboard-coupon"
                  onClick={() => {
                    const [...coupons] = selectedCoupons;

                    const index = coupons.findIndex((c) => c.id === coupon.id);

                    coupons.splice(index, 1);

                    setSelectedCoupons(coupons);
                  }}
                >
                  {coupon.code} <span>&#10060;</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="dashboard-panel">
          <label>
            <span className="filter-label">Select users:</span>

            <select
              onChange={(e) => {
                const name = e.target.value;

                const user = usersByCouponFiltered?.find(
                  (u: any) => u.fullName === name
                );

                if (!selectedUsers.find((u) => u.id === user.id)) {
                  setSelectedUsers([
                    { id: user.id, name: user.fullName },
                    ...selectedUsers,
                  ]);
                }
              }}
            >
              <option key={0} value="All">
                {isLoadingCoupons ? "Loading..." : "All"}
              </option>

              {usersByCouponFiltered?.map((u: any) => (
                <option key={u.id} value={u.fullName}>
                  {u.fullName}
                </option>
              ))}
            </select>
          </label>

          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedUsers.map((u) => (
              <div key={u.id} style={{ marginLeft: 10 }}>
                {u.name},
              </div>
            ))}
          </div>
        </div>

        <div className="dashboard-panel">
          <Button size="lg" onClick={() => fetchCompatibilityMatrix()}>
            Get Compatibility Matrix
          </Button>

          <Button
            size="lg"
            style={{ marginLeft: 20 }}
            onClick={() => {
              setSelectedUsers([]);
              setSelectedCompatibility([]);
            }}
          >
            Reset Users
          </Button>
        </div>

        <div className="chart-row">
          <div>
            <h2>Compatibility Matrix:</h2>

            {!m && !isLoadingCompatibilityMatrix && (
              <div>
                Please, select users and click "Get Compatibility Matrix"
                button.
              </div>
            )}

            {isLoadingCompatibilityMatrix && <div>Loading...</div>}

            {m && (
              <table className="compatibility-table">
                <tbody>
                  <tr key={24324}>
                    <td></td>
                    {Object.keys(m)?.map((key: any) => {
                      const c = Object.entries(m[key]);

                      const currentUserId = c[0][0];

                      const userData: any = usersByCoupon?.find(
                        (f: any) => f.id == currentUserId
                      );

                      return <td key={userData.id}>{userData.fullName}</td>;
                    })}
                  </tr>

                  {Object.keys(m)?.map((key: any) => {
                    const c = Object.entries(m[key]);

                    const currentUserId = c[0][0];

                    const userData: any = usersByCoupon?.find(
                      (f: any) => f.id == currentUserId
                    );

                    const data: any = c[0][1];

                    return (
                      <tr
                        key={`${userData.id}-${currentUserId}`}
                        onClick={() => {
                          setSelectedCompatibility(data);
                        }}
                      >
                        {data?.map((el: any, i: number) => {
                          return i === 0 ? (
                            <>
                              <td key={`${el.id}-1`}>{userData.fullName}</td>
                              <td
                                key={`${el.id}-2`}
                                style={{
                                  background: getBackground(el.compatibility),
                                }}
                                title={`Compatibility: ${el.compatibility}`}
                              />
                            </>
                          ) : (
                            <td
                              key={`${el.id}-1`}
                              style={{
                                background: getBackground(el.compatibility),
                              }}
                              title={`Compatibility: ${el.compatibility}`}
                            />
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>

        {
          <div
            className="chart-row center-align-axis"
            style={{ marginTop: 50 }}
          >
            <h2>Individual Report:</h2>

            {!!selectedCompatibility.length ? (
              <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  {/* <CartesianGrid /> */}
                  <XAxis
                    domain={[0, 100]}
                    type="number"
                    dataKey="compatibility"
                    name="compatibility"
                    // unit="cm"
                    tickLine={false}
                    label={{
                      value: "Compatibility",
                      position: "insideTopLeft",
                      dx: -10,
                      dy: -30,
                    }}
                  />

                  <YAxis
                    // dx={100}
                    label={{
                      value: selectedUserForIndividualReport,
                      position: "top",
                      // dx: -10,
                      // dy: -30,
                    }}
                    type="number"
                    dataKey="id"
                    name="id"
                    // unit="kg"
                    tick={false}
                    // tickLine={false}
                  />

                  <Tooltip
                    cursor={{
                      opacity: 0,
                      strokeDasharray: "3 3",
                    }}
                  />

                  <Scatter
                    activeShape={args.activeShape}
                    activeIndex={args.activeIndex}
                    name="A school"
                    data={individualReportData}
                    // fill="black"
                  >
                    <LabelList
                      dataKey="user"
                      position="right"
                      style={{ fontSize: "10px" }}
                    />

                    {individualReportData.map((entry) => (
                      <Cell key={`cell-${entry.id}`} fill={entry.color} />
                    ))}
                  </Scatter>
                </ScatterChart>
              </ResponsiveContainer>
            ) : (
              <div>
                Please, click on the person in the Compatibility Matrix to view
                the Individual Report
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
};
