import { useQuery } from "react-query";

type WorkGroupsPayload = {
  amount_best: number;
  amount_worse: number;
  workGroupData: any[];
};

type CompatibilityMatrixPayload = {
  workGroupData?: any[];
  users: number;
};

export const useWorkGroups = ({
  amount_best,
  amount_worse,
  workGroupData,
}: WorkGroupsPayload) => {
  return useQuery({
    queryKey: ["work-groups"],
    queryFn: async ({}) => {
      const response = await fetch(
        `${process.env.REACT_APP_AI_BASE_URL}/dashboard/work_groups`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            amount_best,
            amount_worse,
            data: workGroupData,
          }),
        }
      );

      const data = await response.json();

      return data;
    },
  });
};

export const useCompatibilityMatrix = ({
  workGroupData,
  users,
}: CompatibilityMatrixPayload) => {
  return useQuery({
    queryKey: ["compatibility-matrix", users],
    queryFn: async () => {
      const response = await fetch(
        `${process.env.REACT_APP_AI_BASE_URL}/dashboard/compatibility_matrix`,
        {
          method: "POST",
          headers: { "Content-type": "application/json" },
          body: JSON.stringify({
            data: workGroupData,
          }),
        }
      );

      const data = await response.json();

      return data;
    },
    // enabled: !!workGroupData?.length,
    enabled: false,
  });
};
